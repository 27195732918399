import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/react/action-bar/ActionBar.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const InternalContent = makeShortcode("InternalContent");
const SideBySide = makeShortcode("SideBySide");
const Suggestion = makeShortcode("Suggestion");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/react/action-bar" storybook="https://workday.github.io/canvas-kit/?path=/story/components-buttons-action-bar--basic" figma="https://www.figma.com/design/6Y3uHyGKnS8m0DzWBLeL73/Canvas-Web-v11?node-id=19740-8419&t=gwsyYMobzrKIOcBB-4" package="@workday/canvas-kit-react" mdxType="PackageInfo" />
      <h2 {...{
        "id": "anatomy"
      }}>{`Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/45dc88010d7e6e995fc9a080353ba642/536c7/component-anatomy-action-bar.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "30%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of a Action Bar with one primary button, two secondary buttons and an Icon Button.",
              "title": "Image of a Action Bar with one primary button, two secondary buttons and an Icon Button.",
              "src": "/static/45dc88010d7e6e995fc9a080353ba642/50383/component-anatomy-action-bar.png",
              "srcSet": ["/static/45dc88010d7e6e995fc9a080353ba642/1efb2/component-anatomy-action-bar.png 370w", "/static/45dc88010d7e6e995fc9a080353ba642/50383/component-anatomy-action-bar.png 740w", "/static/45dc88010d7e6e995fc9a080353ba642/536c7/component-anatomy-action-bar.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Primary Button:`}</strong>{` A button that is discoverable and used as the most important action to take
on a page.`}</li>
        <li parentName="ol"><strong parentName="li">{`Secondary Button:`}</strong>{` A button or set of buttons that is less important than the Primary Button.`}</li>
        <li parentName="ol"><strong parentName="li">{`Overflow Menu:`}</strong>{` An Icon-Only Secondary Button Variant with an Ellipsis Icon used for
responsive screens or small page width to show more actions.`}</li>
        <li parentName="ol"><strong parentName="li">{`Container Bar:`}</strong>{` The Container Bar is used to house action buttons and is anchored at the
bottom of the screen.`}</li>
      </ol>
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <ul>
        <li parentName="ul">{`Primary Buttons should only be used once per screen. If there are other buttons on screen, use the
Secondary or Tertiary Buttons. However, Tertiary Buttons should not be on the Action Bar unless
it's in the Overflow Menu.`}</li>
        <li parentName="ul">{`Action Bars are placed at the bottom of the screen, and will stick as the user scrolls.`}</li>
        <li parentName="ul">{`Although actions may change, the placement of the Action Bar should persist until the task is
successfully submitted.`}</li>
        <li parentName="ul">{`Action Bars can contain up to 3 actions and an Overflow Menu when appropriate.`}</li>
        <li parentName="ul">{`If there are more than 3 actions, hide the 4th and other remaining actions in an Overflow Menu
that is launched by clicking the `}<a parentName="li" {...{
            "href": "/components/buttons/button"
          }}>{`Icon Only Secondary Button Variant`}</a>{`.`}</li>
        <li parentName="ul">{`There should be between 1-7 items to choose from in an Overflow Menu.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/components/buttons/button"
          }}>{`Buttons`}</a>{` placed in Action Bars should be grouped logically, either by
usage or importance.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When to Use`}</h3>
      <ul>
        <li parentName="ul">{`Use Action Bars for tasks that require navigating between pages, saving progress, submitting a
task, or cancelling a task.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use-something-else"
      }}>{`When to Use Something Else`}</h3>
      <ul>
        <li parentName="ul">{`Consider taking a `}<a parentName="li" {...{
            "href": "/components/buttons/button"
          }}>{`Button`}</a>{` outside of the Action Bar if the action is
not related to the progress or status of a task.`}</li>
        <li parentName="ul">{`Consider using a Text Button instead of an Action Bar if an action is less popular or less
important.`}</li>
      </ul>
      <CKDocs mdxType="CKDocs" />
      <h2 {...{
        "id": "accessibility-guidelines"
      }}>{`Accessibility Guidelines`}</h2>
      <h3 {...{
        "id": "keyboard-interaction"
      }}>{`Keyboard Interaction`}</h3>
      <p>{`Each Button in the Action Bar must have a focus indicator that is highly visible against the
background and against the non-focused state. Refer to
`}<a parentName="p" {...{
          "href": "/guidelines/accessibility/accessible-color"
        }}>{`Accessible Colors`}</a>{` for more information.`}</p>
      <p>{`Action Bar must support the following keyboard interactions:`}</p>
      <ul>
        <li parentName="ul"><inlineCode parentName="li">{`Tab`}</inlineCode>{`: focus each Button element in the Action Bar`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Enter`}</inlineCode>{` or `}<inlineCode parentName="li">{`Space`}</inlineCode>{`: activate focused Button element`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Esc`}</inlineCode>{`: dismiss overflow menu of the action bar (if applicable)`}</li>
        <li parentName="ul">{`[With overflow]`}{` `}<inlineCode parentName="li">{`Up Arrow`}</inlineCode>{` or `}<inlineCode parentName="li">{`Down Arrow`}</inlineCode>{`: navigates among the overflow menu items`}</li>
      </ul>
      <h3 {...{
        "id": "screen-reader-interaction"
      }}>{`Screen Reader Interaction`}</h3>
      <p>{`Action Bar must communicate the following to users:`}</p>
      <ul>
        <li parentName="ul">{`The accessible name for each button in the action bar`}</li>
        <li parentName="ul">{`The “more actions” icon button has an attached menu or “popup” (if applicable)`}</li>
        <li parentName="ul">{`Total number of menu items in the overflow menu (if applicable)`}</li>
      </ul>
      <h3 {...{
        "id": "design-annotations-needed"
      }}>{`Design Annotations Needed`}</h3>
      <p>{`No design annotations are needed for Action Bar`}</p>
      <h3 {...{
        "id": "implementation-markup-needed"
      }}>{`Implementation Markup Needed`}</h3>
      <ul>
        <li parentName="ul">{`An `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` string is required for the overflow icon-only Button variants and accessible
`}<a parentName="li" {...{
            "href": "/components/popups/tooltip"
          }}>{`tooltips`}</a>{` can show the icon's name for everyone.`}</li>
        <li parentName="ul">{`Action Bar can be rendered as a `}<inlineCode parentName="li">{`<section>`}</inlineCode>{` element with an `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` string to enable a
landmark region for screen reader users.`}</li>
        <li parentName="ul">{`[Included in component]`}{` The overflow icon-only Button variants require an `}<inlineCode parentName="li">{`aria-haspopup=”true”`}</inlineCode>{`
and `}<inlineCode parentName="li">{`aria-expanded`}</inlineCode>{` attribute for the attached menu.`}</li>
        <li parentName="ul">{`[Included in component]`}{` The menu container must have an ARIA `}<inlineCode parentName="li">{`role=“menu”`}</inlineCode>{` attribute.`}</li>
        <li parentName="ul">{`[Included in component]`}{` All child elements inside the menu container must have an ARIA
`}<inlineCode parentName="li">{`role=“menuitem”`}</inlineCode>{` attribute.`}</li>
      </ul>
      <h2 {...{
        "id": "content-guidelines"
      }}>{`Content Guidelines`}</h2>
      <ul>
        <li parentName="ul">{`When naming buttons within an Action Bar, refer to the
`}<a parentName="li" {...{
            "href": "/guidelines/content/ui-text/buttons-ctas"
          }}>{`Buttons and Calls to Action`}</a>{` section of the Content Style Guide.`}</li>
      </ul>
    </TabPanel>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="iOS" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/MtaQChthxt2KIX8oveeWQw/Canvas-Mobile?node-id=7736%3A5665&t=pyJVEluqEmBHj6mL-4" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-1"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/8b88b6b15f8972a50575d6fd1c6aeb53/536c7/component-anatomy-actionbar-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "The basic elements of the action bar",
                "title": "The basic elements of the action bar",
                "src": "/static/8b88b6b15f8972a50575d6fd1c6aeb53/50383/component-anatomy-actionbar-mobile.png",
                "srcSet": ["/static/8b88b6b15f8972a50575d6fd1c6aeb53/1efb2/component-anatomy-actionbar-mobile.png 370w", "/static/8b88b6b15f8972a50575d6fd1c6aeb53/50383/component-anatomy-actionbar-mobile.png 740w", "/static/8b88b6b15f8972a50575d6fd1c6aeb53/536c7/component-anatomy-actionbar-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Primary Button`}</strong>{`: Used as the most important action on a page or task.`}</li>
          <li parentName="ol"><strong parentName="li">{`Secondary Button`}</strong>{`: Actions that are less important than Primary Buttons.`}</li>
          <li parentName="ol"><strong parentName="li">{`Overflow Button`}</strong>{`: A Secondary Icon-Only Button that opens an Overflow Menu.`}</li>
          <li parentName="ol"><strong parentName="li">{`Bar Container`}</strong>{`: Container that groups buttons, anchored to the bottom of the screen.`}</li>
        </ol>
        <h2 {...{
          "id": "variations"
        }}>{`Variations`}</h2>
        <h3 {...{
          "id": "mobile"
        }}>{`Mobile`}</h3>
        <p>{`Mobile action bars can contain up to one Primary Button and one Secondary Button, with additional
actions placed in an Overflow Menu.`}</p>
        <h4 {...{
          "id": "overflow"
        }}>{`Overflow`}</h4>
        <p>{`In the case of more than two calls to action in the bar, any overflowing actions should be placed in
the Overflow Menu. Tapping on the Overflow Button will open a Menu with those Secondary or Tertiary
Actions.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/435ae8b7696448537ce14a4e95d8c138/536c7/component-variation-phone-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Placeholder alt text",
                "title": "Placeholder alt text",
                "src": "/static/435ae8b7696448537ce14a4e95d8c138/50383/component-variation-phone-mobile.png",
                "srcSet": ["/static/435ae8b7696448537ce14a4e95d8c138/1efb2/component-variation-phone-mobile.png 370w", "/static/435ae8b7696448537ce14a4e95d8c138/50383/component-variation-phone-mobile.png 740w", "/static/435ae8b7696448537ce14a4e95d8c138/536c7/component-variation-phone-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`If the text within the Primary Button is too long to fit, the Secondary or Tertiary button(s) should
move into the Overflow Menu to allow room for a full-width Primary Button.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/1c48e9b52d4a874463693e4b36868ab3/536c7/component-variation-overflow-phoneoverflow-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Overflow behavior on action bar for mobile devices",
                "title": "Overflow behavior on action bar for mobile devices",
                "src": "/static/1c48e9b52d4a874463693e4b36868ab3/50383/component-variation-overflow-phoneoverflow-mobile.png",
                "srcSet": ["/static/1c48e9b52d4a874463693e4b36868ab3/1efb2/component-variation-overflow-phoneoverflow-mobile.png 370w", "/static/1c48e9b52d4a874463693e4b36868ab3/50383/component-variation-overflow-phoneoverflow-mobile.png 740w", "/static/1c48e9b52d4a874463693e4b36868ab3/536c7/component-variation-overflow-phoneoverflow-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "tablet"
        }}>{`Tablet`}</h3>
        <p>{`Action Bars on tablets may include up to one Primary and two Secondary Buttons.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2b74213f044753619d081f484d2cfd50/536c7/component-variation-tablet-actionbar-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Action bar on tablet with one Primary and two Secondary Buttons",
                "title": "Action bar on tablet with one Primary and two Secondary Buttons",
                "src": "/static/2b74213f044753619d081f484d2cfd50/50383/component-variation-tablet-actionbar-mobile.png",
                "srcSet": ["/static/2b74213f044753619d081f484d2cfd50/1efb2/component-variation-tablet-actionbar-mobile.png 370w", "/static/2b74213f044753619d081f484d2cfd50/50383/component-variation-tablet-actionbar-mobile.png 740w", "/static/2b74213f044753619d081f484d2cfd50/536c7/component-variation-tablet-actionbar-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h4 {...{
          "id": "overflow-1"
        }}>{`Overflow`}</h4>
        <p>{`In the case of more than two calls to action in the bar, all overflowing actions should be placed in
the Overflow Menu. Tapping on the Overflow Button will open a Menu containing those Secondary or
Tertiary Actions.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/dc3143143cc8a1ad01394577d347cfc7/536c7/component-variation-tabletoverflow-actionbar-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Action bar with an overflow button.",
                "title": "Action bar with an overflow button.",
                "src": "/static/dc3143143cc8a1ad01394577d347cfc7/50383/component-variation-tabletoverflow-actionbar-mobile.png",
                "srcSet": ["/static/dc3143143cc8a1ad01394577d347cfc7/1efb2/component-variation-tabletoverflow-actionbar-mobile.png 370w", "/static/dc3143143cc8a1ad01394577d347cfc7/50383/component-variation-tabletoverflow-actionbar-mobile.png 740w", "/static/dc3143143cc8a1ad01394577d347cfc7/536c7/component-variation-tabletoverflow-actionbar-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`If the Primary Button length is too long to fit the Secondary Button beside it, the Secondary Button
should be placed in the Overflow Menu.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/dc3143143cc8a1ad01394577d347cfc7/536c7/component-variation-tabletoverflow-actionbar-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Action bar on tablet with one Primary and two Secondary Buttons.",
                "title": "Action bar on tablet with one Primary and two Secondary Buttons.",
                "src": "/static/dc3143143cc8a1ad01394577d347cfc7/50383/component-variation-tabletoverflow-actionbar-mobile.png",
                "srcSet": ["/static/dc3143143cc8a1ad01394577d347cfc7/1efb2/component-variation-tabletoverflow-actionbar-mobile.png 370w", "/static/dc3143143cc8a1ad01394577d347cfc7/50383/component-variation-tabletoverflow-actionbar-mobile.png 740w", "/static/dc3143143cc8a1ad01394577d347cfc7/536c7/component-variation-tabletoverflow-actionbar-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "usage-guidance-1"
        }}>{`Usage Guidance`}</h2>
        <ul>
          <li parentName="ul">{`There should only be one
`}<a parentName="li" {...{
              "href": "/components/buttons/button#tab=ios&heading=primary-button"
            }}>{`Primary Button`}</a>{` in the Action Bar. If
there are additional actions, use
`}<a parentName="li" {...{
              "href": "/components/buttons/button#tab=ios&heading=secondary-button"
            }}>{`Secondary Buttons`}</a>{`.
`}<a parentName="li" {...{
              "href": "/components/buttons/button#tab=ios&heading=tertiary-button"
            }}>{`Tertiary Buttons`}</a>{` should only appear
as actions in the Overflow Menu.`}</li>
          <li parentName="ul">{`Action Bars are placed at the bottom of the screen. If there is scrollable content, the Action Bar
should remain sticky as the user scrolls.`}</li>
          <li parentName="ul">{`Although actions may change, the placement of the Action Bar should persist until the task is
successfully submitted.`}</li>
          <li parentName="ul">{`Mobile Action Bars can contain up to two visible actions. If there are more than two actions, hide
the third and any other actions in an Overflow Menu that is launched by tapping on the Icon Only
Secondary Button Variant.`}</li>
          <li parentName="ul">{`Tablet Action Bars can contain up to three visible actions. If there are more than three actions,
hide the fourth and any other actions in an Overflow Menu that is launched by tapping on the Icon
Only Secondary Button Variant.`}</li>
          <li parentName="ul">{`Limit the Overflow Menu to a maximum of seven items.`}</li>
          <li parentName="ul">{`Group buttons in Action Bars logically, either by usage or ordered by importance.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-1"
        }}>{`When to Use`}</h3>
        <ul>
          <li parentName="ul">{`Use Action Bars for tasks that require navigating between pages, saving progress, submitting a
task, or canceling a task.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-something-else-1"
        }}>{`When to Use Something Else`}</h3>
        <ul>
          <li parentName="ul">{`Consider taking a Button outside of the Action Bar if the action is not related to the progress or
status of a task.`}</li>
          <li parentName="ul">{`Consider using a `}<a parentName="li" {...{
              "href": "/components/buttons/button#tab=ios&heading=tertiary-button"
            }}>{`Tertiary Button`}</a>{`
instead of an Action Bar if an action is less popular or less important.`}</li>
          <li parentName="ul">{`If actions are not related to a full screen task, consider using a group of buttons instead.`}</li>
        </ul>
        <h3 {...{
          "id": "mobile-guidance"
        }}>{`Mobile Guidance`}</h3>
        <h4 {...{
          "id": "button-placement"
        }}>{`Button Placement`}</h4>
        <p>{`Mobile Action Bars place Primary Buttons on the right-most side to account for a majority of right
handed users.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Place Primary Buttons on the right for easy access." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "708px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/3f61fbebd1dc3c5ca7bbaca31c7a8f97/3cb0f/component-do-primary-actionbar-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "62.16216216216216%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating the correct, right aligned placement of Primary Buttons.",
                    "title": "Image demonstrating the correct, right aligned placement of Primary Buttons.",
                    "src": "/static/3f61fbebd1dc3c5ca7bbaca31c7a8f97/3cb0f/component-do-primary-actionbar-mobile.png",
                    "srcSet": ["/static/3f61fbebd1dc3c5ca7bbaca31c7a8f97/1efb2/component-do-primary-actionbar-mobile.png 370w", "/static/3f61fbebd1dc3c5ca7bbaca31c7a8f97/3cb0f/component-do-primary-actionbar-mobile.png 708w"],
                    "sizes": "(max-width: 708px) 100vw, 708px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Align Primary Buttons to the left of Action Bar" mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "708px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/c58eac3ca57ab849bee48ec577935dcb/3cb0f/component-dont-primary-actionbar-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "62.16216216216216%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating incorrect placement of Primary Buttons in Action Bars.",
                    "title": "Image demonstrating incorrect placement of Primary Buttons in Action Bars.",
                    "src": "/static/c58eac3ca57ab849bee48ec577935dcb/3cb0f/component-dont-primary-actionbar-mobile.png",
                    "srcSet": ["/static/c58eac3ca57ab849bee48ec577935dcb/1efb2/component-dont-primary-actionbar-mobile.png 370w", "/static/c58eac3ca57ab849bee48ec577935dcb/3cb0f/component-dont-primary-actionbar-mobile.png 708w"],
                    "sizes": "(max-width: 708px) 100vw, 708px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h4 {...{
          "id": "button-resizing"
        }}>{`Button Resizing`}</h4>
        <p>{`Buttons in Mobile Action Bars stretch to fit the entire screen width, respecting padding and spacing
tokens.`}</p>
        <h4 {...{
          "id": "only-show-depth-if-content-is-scrollable"
        }}>{`Only Show Depth if Content is Scrollable`}</h4>
        <p>{`If there is scrollable content, provide scrolling indication through use of Depth. If there is no
scrollable content, no Depth is used on the Action Bar with a plain French Vanilla 100 background.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Show Depth if there is scrollable content." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "708px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/124f051557afd280f5c14700575fef54/3cb0f/component-do-scrollable-actionbar-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "62.16216216216216%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image showing depth applied on an Action Bar when there is scrollable content",
                    "title": "Image showing depth applied on an Action Bar when there is scrollable content",
                    "src": "/static/124f051557afd280f5c14700575fef54/3cb0f/component-do-scrollable-actionbar-mobile.png",
                    "srcSet": ["/static/124f051557afd280f5c14700575fef54/1efb2/component-do-scrollable-actionbar-mobile.png 370w", "/static/124f051557afd280f5c14700575fef54/3cb0f/component-do-scrollable-actionbar-mobile.png 708w"],
                    "sizes": "(max-width: 708px) 100vw, 708px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Apply Depth if there is no scrollable content" mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "708px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/82c52ca92ad6a8e552545efd52b3e522/3cb0f/component-dont-scrollable-actionbar-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "62.16216216216216%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image showing depth applied on an Action Bar when there is no scrollable content",
                    "title": "Image showing depth applied on an Action Bar when there is no scrollable content",
                    "src": "/static/82c52ca92ad6a8e552545efd52b3e522/3cb0f/component-dont-scrollable-actionbar-mobile.png",
                    "srcSet": ["/static/82c52ca92ad6a8e552545efd52b3e522/1efb2/component-dont-scrollable-actionbar-mobile.png 370w", "/static/82c52ca92ad6a8e552545efd52b3e522/3cb0f/component-dont-scrollable-actionbar-mobile.png 708w"],
                    "sizes": "(max-width: 708px) 100vw, 708px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h4 {...{
          "id": "device-considerations"
        }}>{`Device Considerations`}</h4>
        <p>{`8dp of bottom padding is taken away from the action bar if it’s rendered on a iPhoneX to account for
the extra spacing for the home indicator.`}</p>
        <h4 {...{
          "id": "text-overflow"
        }}>{`Text Overflow`}</h4>
        <p>{`Buttons within Actions Bars should never wrap to multiple lines. Instead, truncate text when Buttons
reach screen margins.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Truncate text when Buttons reach Action Bar margins." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "708px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/42fe24d90dfd15466c654f5a6c288946/3cb0f/component-do-textoverflow-actionbar-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "62.16216216216216%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image showing truncated Buttons in Action Bars",
                    "title": "Image showing truncated Buttons in Action Bars",
                    "src": "/static/42fe24d90dfd15466c654f5a6c288946/3cb0f/component-do-textoverflow-actionbar-mobile.png",
                    "srcSet": ["/static/42fe24d90dfd15466c654f5a6c288946/1efb2/component-do-textoverflow-actionbar-mobile.png 370w", "/static/42fe24d90dfd15466c654f5a6c288946/3cb0f/component-do-textoverflow-actionbar-mobile.png 708w"],
                    "sizes": "(max-width: 708px) 100vw, 708px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Have Button text wrap to multiple lines." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "708px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/8072dadcc52d1133d9863008ccff01c5/3cb0f/component-dont-textoverflow-actionbar-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "62.16216216216216%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image showing Buttons in action bars wrapping to multiple lines.",
                    "title": "Image showing Buttons in action bars wrapping to multiple lines.",
                    "src": "/static/8072dadcc52d1133d9863008ccff01c5/3cb0f/component-dont-textoverflow-actionbar-mobile.png",
                    "srcSet": ["/static/8072dadcc52d1133d9863008ccff01c5/1efb2/component-dont-textoverflow-actionbar-mobile.png 370w", "/static/8072dadcc52d1133d9863008ccff01c5/3cb0f/component-dont-textoverflow-actionbar-mobile.png 708w"],
                    "sizes": "(max-width: 708px) 100vw, 708px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h2 {...{
          "id": "api-guidelines"
        }}>{`API Guidelines`}</h2>
        <h3 {...{
          "id": "component-definition"
        }}>{`Component Definition`}</h3>
        <p><inlineCode parentName="p">{`actionBarV2(primaryAction:otherActions:style:isContentScrollable:)`}</inlineCode></p>
        <h3 {...{
          "id": "methods"
        }}>{`Methods`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-swift"
          }}>{`public func actionBarV2(
    primaryAction: ActionBarItemModel,
    otherActions: [ActionBarItemModel],
    style: ActionBarV2.ActionBarStyle = .regular,
    isContentScrollable: Bool
) -> some View
`}</code></pre>
        <p>{`Adds an ActionBar to any content as per Workday design requirements.`}</p>
        <h4 {...{
          "id": "parameters"
        }}>{`Parameters`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`primaryAction`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ActionBarItemModel`}</inlineCode>{` that represents the Primary Action`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`otherActions`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Non-primary `}<inlineCode parentName="td">{`ActionBarItemModels`}</inlineCode>{` that would be displayed as Secondary or Overflow actions based on the `}<inlineCode parentName="td">{`ActionBarStyle`}</inlineCode>{`.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`style`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`ActionBarStyle that decides the layout of various actions in the ActionBar.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`isContentScrollable`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Boolean that indicates whether the parent view’s content is scrollable. If yes, ActionBar would have a specific styling with shadow on top.`}</td>
            </tr>
          </tbody>
        </table>
        <h2 {...{
          "id": "accessibility-guidelines-1"
        }}>{`Accessibility Guidelines`}</h2>
        <p>{`Refreshed mobile guidelines will be coming soon!`}</p>
        <h2 {...{
          "id": "content-guidelines-1"
        }}>{`Content Guidelines`}</h2>
        <ul>
          <li parentName="ul">{`When naming buttons within an Action Bar, refer to the Buttons and Calls to Action section of the
`}<a parentName="li" {...{
              "href": "/guidelines/content/ui-text/buttons-ctas"
            }}>{`Content Style Guide`}</a>{`.`}</li>
        </ul>
      </TabPanel>
    </InternalContent>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="Android" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/MtaQChthxt2KIX8oveeWQw/Canvas-Mobile?node-id=7736%3A5665&t=pyJVEluqEmBHj6mL-4" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-2"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/8b88b6b15f8972a50575d6fd1c6aeb53/536c7/component-anatomy-actionbar-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "The basic elements of the action bar",
                "title": "The basic elements of the action bar",
                "src": "/static/8b88b6b15f8972a50575d6fd1c6aeb53/50383/component-anatomy-actionbar-mobile.png",
                "srcSet": ["/static/8b88b6b15f8972a50575d6fd1c6aeb53/1efb2/component-anatomy-actionbar-mobile.png 370w", "/static/8b88b6b15f8972a50575d6fd1c6aeb53/50383/component-anatomy-actionbar-mobile.png 740w", "/static/8b88b6b15f8972a50575d6fd1c6aeb53/536c7/component-anatomy-actionbar-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Primary Button`}</strong>{`: Used as the most important action on a page or task.`}</li>
          <li parentName="ol"><strong parentName="li">{`Secondary Button`}</strong>{`: Actions that are less important than Primary Buttons.`}</li>
          <li parentName="ol"><strong parentName="li">{`Overflow Button`}</strong>{`: A Secondary Icon-Only Button that opens an Overflow Menu.`}</li>
          <li parentName="ol"><strong parentName="li">{`Bar Container`}</strong>{`: Container that groups buttons, anchored to the bottom of the screen.`}</li>
        </ol>
        <h2 {...{
          "id": "variations-1"
        }}>{`Variations`}</h2>
        <h3 {...{
          "id": "mobile-1"
        }}>{`Mobile`}</h3>
        <p>{`Mobile action bars can contain up to one Primary Button and one Secondary Button, with additional
actions placed in an Overflow Menu.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/435ae8b7696448537ce14a4e95d8c138/536c7/component-variation-phone-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Action bar with an overflow button.",
                "title": "Action bar with an overflow button.",
                "src": "/static/435ae8b7696448537ce14a4e95d8c138/50383/component-variation-phone-mobile.png",
                "srcSet": ["/static/435ae8b7696448537ce14a4e95d8c138/1efb2/component-variation-phone-mobile.png 370w", "/static/435ae8b7696448537ce14a4e95d8c138/50383/component-variation-phone-mobile.png 740w", "/static/435ae8b7696448537ce14a4e95d8c138/536c7/component-variation-phone-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h4 {...{
          "id": "overflow-2"
        }}>{`Overflow`}</h4>
        <p>{`In the case of more than two calls to action in the bar, all overflowing actions should be placed in
the Overflow Menu. Tapping on the Overflow Button will open a Menu with those Secondary or Tertiary
Actions.`}</p>
        <p>{`If the text within the Primary Button is too long to fit, the Secondary or Tertiary button(s) should
move into the Overflow Menu to allow room for a full-width Primary Button.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/1c48e9b52d4a874463693e4b36868ab3/536c7/component-variation-overflow-phoneoverflow-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Overflow behavior with one long Primary Button.",
                "title": "Overflow behavior with one long Primary Button.",
                "src": "/static/1c48e9b52d4a874463693e4b36868ab3/50383/component-variation-overflow-phoneoverflow-mobile.png",
                "srcSet": ["/static/1c48e9b52d4a874463693e4b36868ab3/1efb2/component-variation-overflow-phoneoverflow-mobile.png 370w", "/static/1c48e9b52d4a874463693e4b36868ab3/50383/component-variation-overflow-phoneoverflow-mobile.png 740w", "/static/1c48e9b52d4a874463693e4b36868ab3/536c7/component-variation-overflow-phoneoverflow-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "tablet-1"
        }}>{`Tablet`}</h3>
        <p>{`Action Bars on the tablet may include up to one Primary and two Secondary Buttons.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2b74213f044753619d081f484d2cfd50/536c7/component-variation-tablet-actionbar-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Action bar on tablet with one Primary and two Secondary Buttons",
                "title": "Action bar on tablet with one Primary and two Secondary Buttons",
                "src": "/static/2b74213f044753619d081f484d2cfd50/50383/component-variation-tablet-actionbar-mobile.png",
                "srcSet": ["/static/2b74213f044753619d081f484d2cfd50/1efb2/component-variation-tablet-actionbar-mobile.png 370w", "/static/2b74213f044753619d081f484d2cfd50/50383/component-variation-tablet-actionbar-mobile.png 740w", "/static/2b74213f044753619d081f484d2cfd50/536c7/component-variation-tablet-actionbar-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h4 {...{
          "id": "overflow-3"
        }}>{`Overflow`}</h4>
        <p>{`In the case of more than two calls to action in the bar, all overflowing actions should be placed in
the Overflow Menu. Tapping on the Overflow Button will open a Menu containing those Secondary or
Tertiary Actions.`}</p>
        <p>{`If the Primary Button length is too long to fit the Secondary Button beside it, the Secondary Button
should be placed in the Overflow Menu.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/dc3143143cc8a1ad01394577d347cfc7/536c7/component-variation-tabletoverflow-actionbar-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.972972972972975%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Placeholder alt text",
                "title": "Placeholder alt text",
                "src": "/static/dc3143143cc8a1ad01394577d347cfc7/50383/component-variation-tabletoverflow-actionbar-mobile.png",
                "srcSet": ["/static/dc3143143cc8a1ad01394577d347cfc7/1efb2/component-variation-tabletoverflow-actionbar-mobile.png 370w", "/static/dc3143143cc8a1ad01394577d347cfc7/50383/component-variation-tabletoverflow-actionbar-mobile.png 740w", "/static/dc3143143cc8a1ad01394577d347cfc7/536c7/component-variation-tabletoverflow-actionbar-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "usage-guidance-2"
        }}>{`Usage Guidance`}</h2>
        <ul>
          <li parentName="ul">{`There should only be one
`}<a parentName="li" {...{
              "href": "/components/buttons/button#tab=android&heading=primary-button-2"
            }}>{`Primary Button`}</a>{` in the Action
Bar. If there are additional actions, use
`}<a parentName="li" {...{
              "href": "/components/buttons/button#tab=android&heading=secondary-button-2"
            }}>{`Secondary Buttons`}</a>{`.
`}<a parentName="li" {...{
              "href": "/components/buttons/button#tab=android&heading=tertiary-button-2"
            }}>{`Tertiary Buttons`}</a>{` should only
appear as actions in the Overflow Menu.`}</li>
          <li parentName="ul">{`Action Bars are placed at the bottom of the screen. If there is scrollable content, the Action Bar
should remain sticky as the user scrolls.`}</li>
          <li parentName="ul">{`Although actions may change, the placement of the Action Bar should persist until the task is
successfully submitted.`}</li>
          <li parentName="ul">{`Mobile Action Bars can contain up to two visible actions. If there are more than two actions, hide
the third and any other actions in an Overflow Menu that is launched by tapping on the Icon Only
Secondary Button Variant.`}</li>
          <li parentName="ul">{`Tablet Action Bars can contain up to three visible actions. If there are more than three actions,
hide the fourth and any other actions in an Overflow Menu that is launched by tapping on the Icon
Only Secondary Button Variant.`}</li>
          <li parentName="ul">{`Limit the Overflow Menu to a maximum of seven items.`}</li>
          <li parentName="ul">{`Group buttons in Action Bars logically, either by usage or ordered by importance.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-2"
        }}>{`When to Use`}</h3>
        <ul>
          <li parentName="ul">{`Use Action Bars for tasks that require navigating between pages, saving progress, submitting a
task, or canceling a task.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-something-else-2"
        }}>{`When to Use Something Else`}</h3>
        <ul>
          <li parentName="ul">{`Consider taking a Button outside of the Action Bar if the action is not related to the progress or
status of a task.`}</li>
          <li parentName="ul">{`Consider using a
`}<a parentName="li" {...{
              "href": "/components/buttons/button#tab=android&heading=tertiary-button-2"
            }}>{`Tertiary Button`}</a>{` instead of an
Action Bar if an action is less popular or less important.`}</li>
          <li parentName="ul">{`If actions are not related to a full screen task, consider using a group of buttons instead.`}</li>
        </ul>
        <h3 {...{
          "id": "mobile-guidance-1"
        }}>{`Mobile Guidance`}</h3>
        <h4 {...{
          "id": "button-placement-1"
        }}>{`Button Placement`}</h4>
        <p>{`Mobile Action Bars place Primary Buttons on the right-most side to account for a majority of right
handed users.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Place Primary Buttons on the right for easy access." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "708px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/3f61fbebd1dc3c5ca7bbaca31c7a8f97/3cb0f/component-do-primary-actionbar-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "62.16216216216216%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating the correct, right aligned placement of Primary Buttons",
                    "title": "Image demonstrating the correct, right aligned placement of Primary Buttons",
                    "src": "/static/3f61fbebd1dc3c5ca7bbaca31c7a8f97/3cb0f/component-do-primary-actionbar-mobile.png",
                    "srcSet": ["/static/3f61fbebd1dc3c5ca7bbaca31c7a8f97/1efb2/component-do-primary-actionbar-mobile.png 370w", "/static/3f61fbebd1dc3c5ca7bbaca31c7a8f97/3cb0f/component-do-primary-actionbar-mobile.png 708w"],
                    "sizes": "(max-width: 708px) 100vw, 708px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Align Primary Buttons to the left of Action Bar" mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "708px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/c58eac3ca57ab849bee48ec577935dcb/3cb0f/component-dont-primary-actionbar-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "62.16216216216216%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image demonstrating incorrect placement of Primary Buttons in Action Bars",
                    "title": "Image demonstrating incorrect placement of Primary Buttons in Action Bars",
                    "src": "/static/c58eac3ca57ab849bee48ec577935dcb/3cb0f/component-dont-primary-actionbar-mobile.png",
                    "srcSet": ["/static/c58eac3ca57ab849bee48ec577935dcb/1efb2/component-dont-primary-actionbar-mobile.png 370w", "/static/c58eac3ca57ab849bee48ec577935dcb/3cb0f/component-dont-primary-actionbar-mobile.png 708w"],
                    "sizes": "(max-width: 708px) 100vw, 708px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h4 {...{
          "id": "button-resizing-1"
        }}>{`Button Resizing`}</h4>
        <p>{`Buttons in Mobile Action Bars stretch to fit the entire screen width, respecting padding and spacing
tokens.`}</p>
        <h4 {...{
          "id": "only-show-depth-if-content-is-scrollable-1"
        }}>{`Only Show Depth if Content is Scrollable`}</h4>
        <p>{`If there is scrollable content, provide scrolling indication through use of Depth. If there is no
scrollable content, no Depth is used on the Action Bar with a plain French Vanilla 100 background.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Show Depth if there is scrollable content." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "708px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/124f051557afd280f5c14700575fef54/3cb0f/component-do-scrollable-actionbar-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "62.16216216216216%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image showing depth applied on an Action Bar when there is scrollable content",
                    "title": "Image showing depth applied on an Action Bar when there is scrollable content",
                    "src": "/static/124f051557afd280f5c14700575fef54/3cb0f/component-do-scrollable-actionbar-mobile.png",
                    "srcSet": ["/static/124f051557afd280f5c14700575fef54/1efb2/component-do-scrollable-actionbar-mobile.png 370w", "/static/124f051557afd280f5c14700575fef54/3cb0f/component-do-scrollable-actionbar-mobile.png 708w"],
                    "sizes": "(max-width: 708px) 100vw, 708px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Apply Depth if there is no scrollable content" mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "708px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/82c52ca92ad6a8e552545efd52b3e522/3cb0f/component-dont-scrollable-actionbar-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "62.16216216216216%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image showing depth applied on an Action Bar when there is no scrollable content",
                    "title": "Image showing depth applied on an Action Bar when there is no scrollable content",
                    "src": "/static/82c52ca92ad6a8e552545efd52b3e522/3cb0f/component-dont-scrollable-actionbar-mobile.png",
                    "srcSet": ["/static/82c52ca92ad6a8e552545efd52b3e522/1efb2/component-dont-scrollable-actionbar-mobile.png 370w", "/static/82c52ca92ad6a8e552545efd52b3e522/3cb0f/component-dont-scrollable-actionbar-mobile.png 708w"],
                    "sizes": "(max-width: 708px) 100vw, 708px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h4 {...{
          "id": "device-considerations-1"
        }}>{`Device Considerations`}</h4>
        <p>{`8dp of bottom padding is taken away from the action bar if it’s rendered on a iPhoneX to account for
the extra spacing for the home indicator.`}</p>
        <h4 {...{
          "id": "text-overflow-1"
        }}>{`Text Overflow`}</h4>
        <p>{`Buttons within Actions Bars should never wrap to multiple lines. Instead, truncate text when Buttons
reach screen margins.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Truncate text when Buttons reach Action Bar margins." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "708px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/42fe24d90dfd15466c654f5a6c288946/3cb0f/component-do-textoverflow-actionbar-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "62.16216216216216%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image showing truncated Buttons in Action Bars",
                    "title": "Image showing truncated Buttons in Action Bars",
                    "src": "/static/42fe24d90dfd15466c654f5a6c288946/3cb0f/component-do-textoverflow-actionbar-mobile.png",
                    "srcSet": ["/static/42fe24d90dfd15466c654f5a6c288946/1efb2/component-do-textoverflow-actionbar-mobile.png 370w", "/static/42fe24d90dfd15466c654f5a6c288946/3cb0f/component-do-textoverflow-actionbar-mobile.png 708w"],
                    "sizes": "(max-width: 708px) 100vw, 708px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Have Button text wrap to multiple lines." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "708px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/8072dadcc52d1133d9863008ccff01c5/3cb0f/component-dont-textoverflow-actionbar-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "62.16216216216216%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image showing Buttons in action bars wrapping to multiple lines.",
                    "title": "Image showing Buttons in action bars wrapping to multiple lines.",
                    "src": "/static/8072dadcc52d1133d9863008ccff01c5/3cb0f/component-dont-textoverflow-actionbar-mobile.png",
                    "srcSet": ["/static/8072dadcc52d1133d9863008ccff01c5/1efb2/component-dont-textoverflow-actionbar-mobile.png 370w", "/static/8072dadcc52d1133d9863008ccff01c5/3cb0f/component-dont-textoverflow-actionbar-mobile.png 708w"],
                    "sizes": "(max-width: 708px) 100vw, 708px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h2 {...{
          "id": "api-guidelines-1"
        }}>{`API Guidelines`}</h2>
        <h3 {...{
          "id": "component-definition-1"
        }}>{`Component Definition`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-kotlin"
          }}>{`@Composable
fun [ActionBarUiComponent](
    modifier: Modifier = Modifier,
    numButtonsShown: [Int] = 1,
    isScrollable: [Boolean](https://kotlinlang.org/api/latest/jvm/stdlib/kotlin/-boolean/index.html) = true,
    buttonList: [List]&lt;[ActionBarButtonItem]&gt;
) {
`}</code></pre>
        <h3 {...{
          "id": "parameters-1"
        }}>{`Parameters`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`numButtonsShown`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Number of individual buttons to be shown Phones will be restricted to 2 buttons and any additional buttons will be pushed into the menu`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`isScrollable`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Boolean to let the component know if the screen is scrollable. When the screen is scrollable, the action bar will have a divider above the action bar.`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`buttonList`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`List of button name, onClickAction pairs Given a list of onClickAction pairs, the first pair on the list will be the primary action button`}</td>
            </tr>
          </tbody>
        </table>
        <h2 {...{
          "id": "accessibility-guidelines-2"
        }}>{`Accessibility Guidelines`}</h2>
        <p>{`Refreshed mobile guidelines will be coming soon!`}</p>
        <h2 {...{
          "id": "content-guidelines-2"
        }}>{`Content Guidelines`}</h2>
        <ul>
          <li parentName="ul">{`When naming buttons within an Action Bar, refer to the Buttons and Calls to Action section of the
`}<a parentName="li" {...{
              "href": "/guidelines/content/ui-text/buttons-ctas"
            }}>{`Content Style Guide`}</a>{`.`}</li>
        </ul>
      </TabPanel>
    </InternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      